import { Component, Input, input, OnInit } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteOffDirective } from '../../../directives/autocomplete-off.directive';
import { DatePickerModule } from 'primeng/datepicker';

@Component({
    selector: 'app-date-dropdown',
    standalone: true,
    imports: [
        CalendarModule,
        ReactiveFormsModule,
        AutocompleteOffDirective,
        DatePickerModule
    ],
    templateUrl: './date-dropdown.component.html',
    styleUrl: './date-dropdown.component.scss'
})
export class DateDropdownComponent implements OnInit {
    @Input({ transform: (value: Date | null) => value || new Date() })
    minDate: Date = new Date();

    @Input() control!: AbstractControl | FormControl;

    placeholder = input<string>('Selecteer een datum');
    dateFormat = input<string>('dd-mm-yy');

    protected formControlInstance!: FormControl;


    ngOnInit() {
        if (this.control instanceof FormControl) {
            this.formControlInstance = this.control;
        } else {
            // If it's not a FormControl, create a new one and sync its value
            this.formControlInstance = new FormControl(this.control.value);

            // Sync the values between the original control and our FormControl
            this.control.valueChanges.subscribe(value => {
                this.formControlInstance.setValue(value, { emitEvent: false });
            });
            this.formControlInstance.valueChanges.subscribe(value => {
                this.control.setValue(value, { emitEvent: false });
            });
        }
    }

    constructor() {
    }
}
