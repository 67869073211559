import {
    Component,
    input,
    model,
    OnDestroy,
    OnInit,
    output,
    signal,
    WritableSignal
} from '@angular/core';
import { SearchDropdownComponent } from '../search-dropdown/search-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    CategoryByIdGQL,
    CategorySearchHit,
    SearchCategoriesGQL
} from '../../../../../../graphql/generated';
import {
    catchError,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    tap
} from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { NgIcon } from '@ng-icons/core';
import { AutocompleteOffDirective } from '../../../directives/autocomplete-off.directive';

@Component({
    selector: 'app-category-search-dropdown',
    standalone: true,
    imports: [
        SearchDropdownComponent,
        FormsModule,
        ReactiveFormsModule,
        NgIcon,
        AutocompleteOffDirective
    ],
    templateUrl: './category-search-dropdown.component.html'
})

export class CategorySearchDropdownComponent implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject<void>();

    id = input<string>('category-search-dropdown');

    public readonly initial = input<string | null>();

    // ----------[ Template Methods ]----------

    placeholder = input<string | null>('Type een categorie');

    public readonly selected = output<CategorySearchHit>();

    // ----------[ State ]----------

    search = model<string | null>(null);
    selectedIndex = signal<number>(-1);
    protected readonly error = signal<string | null>(null);
    protected readonly categories: WritableSignal<CategorySearchHit[]> = signal([]);
    protected readonly shouldSearch = signal<boolean>(true);
    private readonly selectionJustMade = signal<boolean>(false);
    private lastSelectedValue: string | null = null;

    constructor(
        private readonly searchCategoriesGQL: SearchCategoriesGQL,
        private readonly categoryByIdGQL: CategoryByIdGQL
    ) {
        this.initializeCategoriesSignal();
    }

    // ----------[ Dependencies ]----------

    public ngOnInit() {
        const initial = this.initial();
        if (initial) {
            this.categoryByIdGQL
                .watch({ id: initial })
                .valueChanges
                .pipe(
                    map(result => result.data.categoryById)
                ).subscribe(category => {
                if (category) {
                    this.search.set(category.name);
                    this.lastSelectedValue = category.name;
                    this.selectionJustMade.set(true);
                }
            });
        }
    }

    // ----------[ Lifecycle Hooks ]----------

    protected readonly mapOption = (option: CategorySearchHit) => option.category.name;

    // ----------[ Methods ]----------

    protected selectCategorySuggestion(suggestion: CategorySearchHit) {
        this.selected.emit(suggestion);
        const categoryName = this.mapOption(suggestion);
        this.search.set(categoryName);
        this.lastSelectedValue = categoryName;
        this.categories.set([]);
        this.selectionJustMade.set(true);
        this.selectedIndex.set(-1);
    }

    protected onSelected(option: CategorySearchHit) {
        this.selectCategorySuggestion(option);
    }

    protected onKeyDown(event: KeyboardEvent): void {
        switch (event.key) {
            case 'ArrowDown':
                this.moveSelection(1);
                event.preventDefault();
                break;
            case 'ArrowUp':
                this.moveSelection(-1);
                event.preventDefault();
                break;
            case 'Enter':
                this.selectCurrentSuggestion();
                event.preventDefault();
                break;
        }
    }

    private initializeCategoriesSignal(): void {
        const search$ = toObservable(this.search);
        const shouldSearch$ = toObservable(this.shouldSearch);

        combineLatest([ search$, shouldSearch$ ]).pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(([ searchTerm, _ ]) => {
                if (searchTerm !== this.lastSelectedValue) {
                    this.selectionJustMade.set(false);
                }
            }),
            filter(([ _, shouldSearch ]) => shouldSearch && !this.selectionJustMade()),
            switchMap(([ search, _ ]) => {
                if (!search) {
                    return of([]);
                }
                return this.performSearch(search);
            })
        ).subscribe(hits => {
            this.categories.set(hits as CategorySearchHit[]);
        });

        // Reset suggestions when shouldSearch changes to false
        shouldSearch$.pipe(
            filter(shouldSearch => !shouldSearch)
        ).subscribe(() => {
            this.categories.set([]);
        });
    }

    private performSearch(searchTerm: string): Observable<CategorySearchHit[]> {
        return this.searchCategoriesGQL
            .watch({ input: { term: searchTerm } })
            .valueChanges
            .pipe(
                map(result => result.data.searchCategories.hits as CategorySearchHit[]),
                tap(() => this.error.set(null)),
                catchError(err => {
                    console.error('Error searching categories:', err);
                    this.error.set('Er is een fout opgetreden bij het zoeken naar categorieën.');
                    return of([]);
                })
            );
    }

    private moveSelection(step: number): void {
        const newIndex = this.selectedIndex() + step;
        if (newIndex >= -1 && newIndex < this.categories().length) {
            this.selectedIndex.set(newIndex);
        }
    }

    private selectCurrentSuggestion(): void {
        const currentIndex = this.selectedIndex();
        if (currentIndex !== -1) {
            const selectedSuggestion = this.categories()[currentIndex];
            this.selectCategorySuggestion(selectedSuggestion);
        }
    }

    onClose() {
        this.categories.set([]);

    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
