<p-datePicker
    autocompleteOff
    [formControl]="formControlInstance"
    [showIcon]="true"
    [selectOtherMonths]="true"
    iconDisplay="input"
    [dateFormat]="dateFormat()"
    [showTime]="false"
    [showWeek]="true"
    [minDate]="minDate"
    [placeholder]="placeholder()"
    [firstDayOfWeek]="1"
    appendTo="body"
    styleClass="w-full text-xs"
    inputStyleClass="w-full rounded-sm border-0 bg-white py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
/>
