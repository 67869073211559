<app-search-dropdown
    [id]="id()"
    [(ngModel)]="search"
    [options]="categories()"
    [mapOptionLabel]="mapOption"
    (selected)="onSelected($event)"
    (keydown)="onKeyDown($event)"
    [selectedIndex]="selectedIndex()"
    (close)="onClose()"
>
    <div trigger class="w-full">
        <label [for]="id()" class="block text-sm font-medium leading-6 text-gray-900">
            <ng-content select="[label]"/>
        </label>
        <div class="relative w-full">
            <input
                autocompleteOff
                [(ngModel)]="search"
                [id]="id()"
                type="text"
                [placeholder]="placeholder()"
                class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6" role="combobox" aria-controls="options" aria-expanded="false">
            <button type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ng-icon name="heroMagnifyingGlass" class="h-5 w-5 text-gray-500" aria-hidden="true"></ng-icon>
            </button>
        </div>
    </div>
</app-search-dropdown>
